<template>
    <div class="bg-module flex flex-col items-center rounded-lg mx-4 p-3 flex-1">
        <span class="text-xl text-white font-semibold self-start flex flex-1 justify-between w-full">
            <span class="relative inline-block left-o float-left">
                Objetivos
            </span>
            <span class="relative inline-block right-0 float-right"  v-if="
            (edition.com_is_star&&edition.com_is_star !== 0 && edition.com_is_star !== null)||(edition.is_total_type&&course.com_is_star&&course.com_is_star !== 0 && course.com_is_star !== null)
">
                <StarIcon class="inline  w-6 h-6 pb-1"></StarIcon>
            </span>
        </span>
        <span class="text-sm text-secondary">{{ edition.customers_target | numberFormat }}</span>
        <div class="w-2px bg-main h-3 -mt-2px"></div>
        <div class="w-56 lg:w-20rem xl:w-56 h-56 lg:h-20rem xl:h-56 relative flex flex-col">
            <div class="w-full h-full z-10">
                <CourseGauge v-if="edition.customers_target_percent_reached < 100" chartId="oppor" :gaugeData="edition.customers_target_percent_reached" :secondData="edition.opportunities_plus_target_percent_reached" color="#0089AA" secondColor="#874DE2" backColor="#121822" />
                <CourseGauge v-else chartId="oppor" :gaugeData="edition.customers_target_percent_reached - 100" :secondData="edition.opportunities_plus_target_percent_reached" color="#10f0dc" secondColor="#874DE2" backColor="#0089AA" />
            </div>
            <div class="w-full h-full absolute inset-0 flex justify-center flex-col">
                <span class="text-mainSecondary text-3xl font-bold leading-none">{{ edition.customers_size | numberFormat(0) }}</span>
                <span class="text-secondary mb-22">Alumnos</span>

                <span  class="text-main font-medium w-1/3 mx-auto border-t border-body mt-2 opacity-50">{{ edition.customers_target_percent_reached | numberFormat(0) }}%</span>
            </div>
        </div>


        <div style="height: 2px" class="w-full bg-separator2 mt-2 lg:mt-20 xl:mt-2"  v-if="!edition.is_total_type || edition && edition.area_has_opportunities_plus"></div>
        <div style="height: 2px" class="w-full bg-separator"  v-if="!edition.is_total_type|| edition && edition.area_has_opportunities_plus"></div>

        <div class="flex items-center justify-around w-full mt-3 flex-1"  v-if="!edition.is_total_type|| edition && edition.area_has_opportunities_plus">
            <div class="flex flex-col" v-if="!edition.is_total_type">
                <template v-if="edition.is_com == 1||commercialCourseDontStart">
                    <span v-if="edition.com_remaining_days <= 10" class="text-sm text-edemRed">Faltan {{edition.com_remaining_days}} días</span>
                    <span v-else class="text-sm text-secondary">Fecha de inicio</span>
                    <span class="text-secondary font-medium text-2xl">{{ edition.com_end_date | middleDateFormat }}</span>
                </template>
                <template v-else>
                    <span class="text-sm text-secondary">Ya finalizado</span>
                    <span class="text-secondary font-medium text-2xl">{{ edition.com_end_date | middleDateFormat }}</span>
                </template>

            </div>
            <div v-if="edition && edition.area_has_opportunities_plus" class="flex flex-col">
                <span class="text-sm text-secondary">Admitidos pagados</span>
                <div class="flex justify-center items-center">
                    <span class="text-white font-medium text-2xl text-opportunitiesPlus">{{ edition.opportunities_plus_size | numberFormat(0) }}</span>
                    <div class="border-r border-body opacity-50 h-6 mx-3"></div>
                    <span class="text-white font-medium text-opportunitiesPlus opacity-50"> {{ edition.opportunities_plus_target_percent_reached | numberFormat(0) }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CourseGauge from '@/pages/inside/sections/dashboards/charts/CourseGauge';
    import Constants from "../../../../../api/constants";
    import StarIcon from "@/assets/StarIcon";

    export default {
        name: 'CoursesPie',
        props: ['edition', 'course'],
        components: {CourseGauge,StarIcon},
        computed: {
            constants(){
              return  Constants
            },
            courseSecondData(){
                if(this.edition && this.edition.area_has_opportunities_plus){
                    return this.edition.opportunities_plus_target_reached;
                }

                return 0;
            },
            commercialCourseDontStart(){
              let today = this.$dayjs();
              return today.diff(this.edition.com_start_date, 'days') <= 0;
            }
        }
    }
</script>