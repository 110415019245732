<template>
    <div class="flex flex-col bg-module flex-1 min-h-0 rounded-lg p-3">
        <span class="text-white text-xl self-start font-semibold">Solicitudes mensuales</span>

        <div class="w-full flex items-center mt-2">
            <div class="flex items-center mr-6">
                <div class="w-3 h-3 mr-2 rounded-full bg-visits"></div>
                <span class="text-secondary text-xs">Histórico</span>
            </div>
            <div class="flex items-center mr-6">
                <div class="w-3 h-3 mr-2 rounded-full bg-regular"></div>
                <span class="text-secondary text-xs">Objetivo</span>
            </div>
        </div>

        <div v-if="timeLine" class="w-full h-64 mt-1">
            <StackedColumns 
            :data="columnsInfoRequests" 
            :secondData="columnsInscriptions" 
            :objective="timeLine.data.objective" 
            :historical="timeLine.data.lastPeriod" 
            :chartId="'chartId'" 
        />
        </div>

        <Request model="Course" :immediate="true" action="monthlySummary" :form="monthlyFilter"
                 @success="setMonthlyData" ref="getMonthlySummaryRef"/>
        <Request model="Course" :immediate="true" action="gainTimeLine" :form="timelineFilter" @success="setCourseTimeLine"
                 ref="getCourseTimeLineRef"/>

        <div v-if="monthlySummary" class="flex items-center justify-around my-auto pb-4">
            <div class="flex flex-col cursor-pointer items-center">
                <span class="text-xs" :class="{'text-barsInformation': info_requests_selected, 'text-gray-700': !info_requests_selected}">Información</span>
                <div
                        @click="changeInfoRequestsSelection"
                        :class="{ 'text-barsInformation': info_requests_selected, 'border-2': filters.includes('info'), 'border-solid border-barsInformation': filters.includes('info') && info_requests_selected, 'text-gray-700 border-box': !info_requests_selected }"
                        class="w-12 h-12 flex flex-col justify-center items-center shadowData mt-2 text-lg rounded-2xl">
                    {{ monthlySummary.data.info_requests | numberFormat }}
                </div>
            </div>
            <div class="flex flex-col cursor-pointer items-center">
                <span class="text-xs" :class="{'text-barsRegistration': inscriptions_selected, 'text-gray-700': !inscriptions_selected}">Inscripción</span>
                <div
                        @click="changeInscriptionsSelection"
                        :class="{ 'text-barsRegistration': inscriptions_selected, 'border-2': filters.includes('info'), 'border-solid border-barsRegistration': filters.includes('reg') && inscriptions_selected, 'text-gray-700 border-box': !inscriptions_selected }"
                        class="w-12 h-12 flex flex-col justify-center items-center shadowData mt-2 text-lg rounded-2xl ">
                    {{ monthlySummary.data.inscriptions | numberFormat }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StackedColumns from '@/pages/inside/sections/dashboards/charts/StackedColumns'
    import Request from "../../../../../api/components/Request";

    export default {
        props: ['edition'],
        name: 'CoursesBars',
        components: {
            Request,
            StackedColumns
        },
        data() {
            return {
                columns: {
                    registration: [23, 14, 25, 36, 32, 10, 34, 23, 32, 13, 23, 41],
                    information: [13, 14, 25, 12, 32, 18, 29, 32, 14, 42, 12, 20]
                },
                filters: ['reg', 'info'],
                monthlyFilter: {
                    id: this.edition.course_id,
                    from: this.startDayFilter().startOf('year').format('YYYY-MM-DD'),
                    to: this.startDayFilter().endOf('year').format('YYYY-MM-DD')
                },
                timelineFilter: {
                    id: this.edition.course_id,
                    from: this.startDayFilter().startOf('year').format('YYYY-MM-DD'),
                    to: this.startDayFilter().endOf('year').format('YYYY-MM-DD'),
                },
                monthlySummary: null,
                timeLine: null,
                info_requests_selected: true,
                inscriptions_selected: true,
            }
        },
        methods: {
            filterData(filter) {
                const index = this.filters.indexOf(filter)
                this.filters.includes(filter) ? this.filters.splice(index, 1) : this.filters.push(filter)
                !this.filters.includes('reg') ? this.timeLine.data.inscriptions = [] : this.timeLine.data.inscriptions;
                !this.filters.includes('info') ? this.timeLine.data.info_requests = [] : this.timeLine.data.info_requests;
            },
            setMonthlyData(monthlySummary) {
                this.monthlySummary = monthlySummary;
            },
            setCourseTimeLine(timeLine) {
                this.timeLine = timeLine;
            },
            changeInfoRequestsSelection() {
                if (!this.info_requests_selected) {
                    this.info_requests_selected = true;
                    return;
                }

                if (this.inscriptions_selected) {
                    this.info_requests_selected = false;
                }
            },
            startDayFilter(){
                if(!this.edition.is_total_type){
                  return this.$dayjs(this.edition.com_end_date)
                }
                let year = this.edition.name;
                return this.$dayjs(year+'-'+'01-01');
            },
            changeInscriptionsSelection() {
                if (!this.inscriptions_selected) {
                    this.inscriptions_selected = true;
                    return;
                }

                if (this.info_requests_selected) {
                    this.inscriptions_selected = false;
                }
            }
        },
        computed: {
            columnsInfoRequests() {
                if(!this.info_requests_selected){
                    return [];
                }

                return this.timeLine.data.info_requests;
            },
            columnsInscriptions() {
                if(!this.inscriptions_selected){
                    return [];
                }

                return this.timeLine.data.inscriptions;
            }
        },
        watch: {
            courseData(n) {
                this.monthlyFilter.id = n.id;
                this.timelineFilter.id = n.id;
            },
            timeLine() {
                this.columns.registration = [];
                this.columns.information = [];

                let info_requests,
                    inscriptions;

                for (let i = 0; i < this.timeLine.data.length; i++) {
                    info_requests = parseInt(this.timeLine.data[i].info_requests ? this.timeLine.data[i].info_requests : 0);
                    inscriptions = parseInt(this.timeLine.data[i].inscriptions ? this.timeLine.data[i].inscriptions : 0);

                    this.columns.information.push(info_requests);
                    this.columns.registration.push(inscriptions);
                }
            }
        }
    }
</script>