<template>
    <div class="chart-wrap" ref="container-chart">
        <highcharts :class="'gauge'" class="w-full h-full" :options="optionsSolid" :ref="chartId" ></highcharts>
    </div>
</template>

<script>
    export default {
        props: ['gaugeData', 'color', 'chartId', 'secondData', 'secondColor','backColor'],
        data: function() {
            return {
                optionsSolid: {
                    chart: {
                        type: 'solidgauge',
                        plotBackgroundColor: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                        borderWidth: 0,
                        backgroundColor: 'rgba(255,255,255,0)',
                        margin: [0, 0, 0, 0],
                        spacingTop: 0,
                        spacingBottom: 0,
                        spacingRight: 0,
                        spacingLeft: 0,
                        
                        style: {
                            fontFamily: 'Arial',
                        },
                    },
                    title: {
                        text: ''
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: false,
                        style: {
                            //color: '#0089AA',
                        },
                        followTouchMove: true,
                        followPointer: true,
                        borderRadius: 20,
                        //pointFormat: '<b>{series.name}</b><br/><br/><span class="mt-1 font-bold text-base">{point.y}%</span>',
                        useHTML:true,
                        // formatter: function() {
                        //     return '<span style="color:'+this.color+'"><b>'+ this.series.name +
                        //         '</b>: <span class="text-base font-bold">'+ parseFloat(this.y.toFixed(2)).toLocaleString() +'%</span></span>';
                        // }
                    },
                    pane: [
                        {
                            startAngle: 1,
                            endAngle: 359,
                            background: {
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgba(255,255,255,0)'
                            } 
                        },
                        {
                            startAngle: 1,
                            endAngle: 359,
                            background: {
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgba(255,255,255,0)'
                            } 
                        }
                    ],
                    yAxis: [
                        {
                            pane: 0,
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        },
                        {
                            pane: 1,
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        }
                    ],
                    
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true,
                            
                        },
                        series: {
                            animation: {
                                duration: 800
                            }
                        }
                    },

                    series: [
                        
                    ]
                }
            }
        },
        methods: {
            load() {

                let chart = this.$refs[this.chartId].chart;

                for (var i = chart.series.length - 1; i > -1; i--) {
                    chart.series[i].remove();
                }

                chart.addSeries({
                    name: 'backFirst',
                    yAxis: 0,
                    rounded: false,
                    data: [{
                        color: this.backColor,
                        // color: '#293345',
                        radius: '112%',
                        innerRadius: '90%',
                        y: 100,
                    }]
                });

                chart.addSeries({
                    name: 'Alumnos',
                    yAxis: 1,
                    rounded: false,
                    data: [{
                        radius: '112%',
                        innerRadius: '90%',
                        y: this.gaugeData,
                        color: this.color
                    }],
                });

                if(this.secondData) {
                    chart.addSeries({
                        name: 'Oportunidades',
                        rounded: true,
                        yAxis: 1,
                        data: [{
                            radius: '85%',
                            innerRadius: '78%',
                            y: this.secondData,
                            color: this.secondColor
                        }]
                    });
                }
            },
        },
        
        watch: {
            gaugeData(){
                this.load()
            }
            
        },
        updated() {
            this.load();
        },
        mounted() {
            this.load();
        }
    };

</script>
<style>

/*CHARTS RESPONSIVE*/

.chart-wrap{
        margin: auto;
        height: 100% !important;
        width: 100% !important;
        position: relative;
    }
    .chart-wrap .highcharts-container{
        height: 100% !important;
        width: 100% !important;
    }

    .chart-wrap>div{
        height: 100% !important;
        width: 100% !important;                
    }
    .center-absolute{
        position: absolute;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top:50%;
        transform: translateY(-50%);
    }
    .chart-wrap>div svg{
        height: 100% !important;
        width: 100% !important;
    }

    .highcharts-gauge-series .highcharts-pivot {
	stroke-width: 1px;
	stroke: transparent;
	fill-opacity:0;
}

</style>
