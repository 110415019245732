<template>
    <div 
        class="flex flex-col items-center rounded-lg py-3 mx-4 flex-1"
        v-if="!edition.is_total_type"
    >

        <Carousell 
            dots
            class="h-full w-full"
            :max-length="2"
            v-slot="{ active }"
        >
            <CarousellItem
                class="h-full transition-all duration-500 ease-in-out"
                :class="{
                    'transform translate-x-1/10': 0 < active,
                }"
            >
                <CoursesFunnel 
                    class="h-full"
                    :edition="edition"
                    :class="{
                        'w-90por': 0 === active,
                        '-ml-3 w-full': 0 !== active,
                    }"
                />
            </CarousellItem>

            <CarousellItem 
                class="h-full transition-all duration-500 ease-in-out"
                :class="{
                    'transform -translate-x-1/10 ml-3': 1 > active,
                    'transform translate-x-1/10': 1 === active,
                }"
            >
                <CoursesBars 
                    class="h-full"
                    :edition="edition"
                    :class="{
                        'w-90por': 1 === active,
                        'w-full': 1 !== active,
                    }"
                />
            </CarousellItem>
        </Carousell>
    </div>
  <div v-else         class="flex flex-col items-center rounded-lg py-3 mx-4 flex-1">
    <CoursesBars
        class="h-full"
        :edition="edition"
        :class="{
                        'w-90por': 1 === active,
                        'w-full': 1 !== active,
                    }"
    />
  </div>
</template>

<script>
    import { Carousell, CarousellItem } from '@/pages/inside/sections/dashboards/carousell';
    import CoursesFunnel from '@/pages/inside/sections/dashboards/courses/CoursesFunnel'
    import CoursesBars from '@/pages/inside/sections/dashboards/courses/CoursesBars'
    export default {
        props: ['edition'],
        name: 'CoursesCarousel',
        components: {
            Carousell,
            CarousellItem,
            CoursesFunnel,
            CoursesBars
        }
    }
</script>