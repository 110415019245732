<template>
    <div class="relative chart-wrap">
        <highcharts class="chart w-full h-full" :options="options" :id="chartId"></highcharts>
    </div>
</template>

<script>
export default {
    props: ['chartId','data', 'secondData','dataName','colors','objective','historical'],
    computed: {
        options() {
            return {
                chart: {
                    
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    backgroundColor:'rgba(255,255,255,0)',
                    spacingBottom: 10,
                    spacingTop: 50,
                    spacingRight: 10,
                    spacingLeft: 10,
                    marginTop: 10,
                    marginRight: 0,
                    marginLeft: 40,
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                credits:{
                    enabled: false
                },
                xAxis: [
                    {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            month: '%b'
                        },
                        lineColor: 'rgba(255,255,255,0)',
                        title: {
                            text: null
                        },
                        labels: {
                            style:{
                                color:'#4C687E',
                                fontSize: '6px',
                            },
                        },
                        tickPixelInterval: 15,
                        tickWidth: 0,
                        width: '100%',
                    },{
                        type: 'datetime', 
                        lineColor: 'rgba(255,255,255,0)',   
                        labels: {
                            enabled: false
                        },      
                        tickWidth: 0,        
                        opposite: true
                    },
                ],
                time: {
                    timezoneOffset: new Date().getTimezoneOffset()
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    gridLineColor: '#1D3241',
                    allowDecimals: false,
                    labels: {
                        enabled: true,
                        style:{
                            color:'#4C687E',
                            fontSize: '7px',
                        },
                    },
                    tickPixelInterval: 25,
                    min: 0,
                },
                tooltip: {
                    backgroundColor: "rgba(18, 24, 34, 1)",
                    borderWidth: 0,
                    borderRadius: 10,
                    xDateFormat: '%B, %d %Y',
                    shadow: false,
                    useHTML: true,
                    outside: true,
                    // formatter:  function() {
                    //     return `<div style="z-index: 1"><p style="color:#fff">${this.series.name} ${this.y}</p><p style="color: #79849A; font-size: 11px">Total ${this.total}</p></div>`
                    // },
                     formatter: function() {
                      // this = point
                      return this.series.tooltipOptions.customTooltipPerSeries.call(this);
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        borderRadius: 3,
                        borderWidth: 2,
                        borderColor: '#202837',
                        dataLabels: {
                            enabled: false
                        },                        
                    },
                    line: {
                        lineWidth: 4,
                        marker: {
                            enabled: false
                        }
                    },
                    series: {
                        shadow: true,
                        // pointWidth: 15,
                        pointPadding: 0.1,
                    }
                },
                legend: {
                    enabled: false
                },
                
                // data: {
                //     enablePolling: true,
                //     dataRefreshRate: 1
                // },
                series: [
                    {
                        type: 'column',
                        name: 'Inscripción',
                        data: this.secondData,
                        color: '#0BCEFC',
                        shadow: {
                            color: '#230036'
                        },
                        tooltip: {
                            customTooltipPerSeries: function() {
                                return `<div style="z-index: 1"><p style="color:#fff">${this.series.name} ${this.y}</p><p style="color: #79849A; font-size: 11px">Total ${this.total}</p></div>`
                            }
                        }
                    }, 
                    {
                        type: 'column',
                        name: 'Información',
                        data: this.data,
                        color: '#2473A5',
                        shadow: {
                            color: '#230036'
                        },
                        tooltip: {
                            customTooltipPerSeries: function() {
                                return `<div style="z-index: 1"><p style="color:#fff">${this.series.name} ${this.y}</p><p style="color: #79849A; font-size: 11px">Total ${this.total}</p></div>`
                            }
                        }
                    },
                    {
                        type: 'line',
                        name: 'Histórico',
                        data: this.historical,
                        color: '#939597',
                        shadow: {
                            color: '#230036'
                        },
                        xAxis: 1,
                        tooltip: {
                            customTooltipPerSeries: function() {
                                return `<div style="z-index: 1"><p style="color:#fff">${this.series.name} ${this.y}</p></div>`
                            }
                        }
                    },
                    {
                        type: 'line',
                        name: 'Objetivo',
                        data: this.objective,
                        color: '#f6ca4e',
                        shadow: {
                            color: '#230036'
                        },
                        tooltip: {
                            customTooltipPerSeries: function() {
                                return `<div style="z-index: 1"><p style="color:#fff">${this.series.name} ${this.y}</p></div>`
                            }
                        }      
                    },
                ]
            }
        }
    },
};
</script>
