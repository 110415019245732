<template>
  <div class="w-full flex justify-between items-end pl-24 py-0 -mt-16 md:-mt-16 lg:-mt-16">
        <div class="w-full flex flex-col items-start mb-4 pr-2">
            <div class="text-secondary text-sm font-medium mb-1"><slot name="headlineSubtitle"></slot></div>
            <h2 class="text-white text-xl md:text-2xl lg:text-xl font-medium leading-none text-left"> <slot name="headlineTitle"></slot></h2>            
        </div>
  </div>
</template>

<script>
export default {
    name: 'Headline',
    props: {
        isFilter: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>