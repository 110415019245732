var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.edition.is_total_type)?_c('div',{staticClass:"flex flex-col items-center rounded-lg py-3 mx-4 flex-1"},[_c('Carousell',{staticClass:"h-full w-full",attrs:{"dots":"","max-length":2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('CarousellItem',{staticClass:"h-full transition-all duration-500 ease-in-out",class:{
                  'transform translate-x-1/10': 0 < active,
              }},[_c('CoursesFunnel',{staticClass:"h-full",class:{
                      'w-90por': 0 === active,
                      '-ml-3 w-full': 0 !== active,
                  },attrs:{"edition":_vm.edition}})],1),_c('CarousellItem',{staticClass:"h-full transition-all duration-500 ease-in-out",class:{
                  'transform -translate-x-1/10 ml-3': 1 > active,
                  'transform translate-x-1/10': 1 === active,
              }},[_c('CoursesBars',{staticClass:"h-full",class:{
                      'w-90por': 1 === active,
                      'w-full': 1 !== active,
                  },attrs:{"edition":_vm.edition}})],1)]}}],null,false,691096030)})],1):_c('div',{staticClass:"flex flex-col items-center rounded-lg py-3 mx-4 flex-1"},[_c('CoursesBars',{staticClass:"h-full",class:{
                      'w-90por': 1 === _vm.active,
                      'w-full': 1 !== _vm.active,
                  },attrs:{"edition":_vm.edition}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }