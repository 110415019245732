<template>
    <div>
        <Rotate />
        <transition name="fade" mode="out-in" class="z-50">
        <div class="w-screen md:w-3/5 max-w-mobile lg:max-w-tablet h-full min-h-screen mx-auto bg-body flex flex-col no-mobile-shadow">
            <Header class="mt-0 md:mt-2 lg:mt-0">
                <template #menuIcon>
                    <div @click="backToSelection" class="bg-main rounded-2xl w-12 h-12 flex-center text-white mr-6">
                        <i class="mdi mdi-chevron-left text-5xl"></i>
                    </div>
                </template>
                <template #edemLogo>
                    <div></div>
                </template>
            </Header>

            <Request v-if="getCourseId" model="Course" :immediate="true" action="byId" :form="courseFilter" @success="setCourse" ref="getCourseRef" />
            <All v-if="getEditionId" model="Edition" :immediate="true" :sensitive="true" ref="editionsRef" @success="setEditions" :query="editionsQuery" />

            <div v-if="course && editions">
                <HeadlineCourse>
                    <template slot="headlineTitle">
                        <i v-if="course.name" class="mdi mdi-school-outline text-main mr-2"></i>{{ course.name }}
<!--                            <span class="relative right-0 text-md text-yellow-400 mdi mdi-star"></span>-->
                    </template>
                    <template slot="headlineSubtitle">

                        {{ course.area_name }}

                    </template>
                </HeadlineCourse>
                <div class="overflow-hidden w-full h-12 pr-0">
                    <div class="overflow-x-auto flex items-center pb-4 px-3 editions-chip">
                        <div
                            @click="setSelected(index)"
                            v-for="(edition,index) in editions"
                            :key="index"
                            class="w-auto flex-none mx-1 px-3 rounded-full py-1"
                            :class="{'bg-main': index === selected, 'bg-mainSelected': index !== selected}">
                            <span :class="{'text-white': index === selected,'text-clientsSelected': index !== selected}" class="text-xs">{{ edition.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <CoursesPie v-if="edition" :key="pieKey" :edition="edition" :course="course"/>
            <CoursesCarousel v-if="edition" :key="'carousel'+carouselKey" :edition="edition" />
        </div>
        </transition>
    </div>
</template>

<script>
    import Header from "@/pages/inside/sections/dashboards/Header"
    import Constants from "../../../../api/constants";
    import Rotate from "@/pages/inside/sections/dashboards/Rotate"
    import HeadlineCourse from "@/pages/inside/sections/dashboards/HeadlineCourse"
    import CoursesPie from "@/pages/inside/sections/dashboards/courses/CoursesPie"
    import CoursesCarousel from "@/pages/inside/sections/dashboards/courses/CoursesCarousel"
    import All from "../../../../api/components/All";
    import Request from "../../../../api/components/Request";


    export default {
        name: 'DashboardCourse',
        components: {
            Request,
            All,
            Header,
            Rotate,
            HeadlineCourse,
            CoursesPie,
            CoursesCarousel
        },
        computed: {
            courseFilter(){
                return {
                    id:this.getCourseId()
                };
            },
            editionFilter(){
                return {
                    id:this.getEditionId()
                };
            },
            editionsQuery(){
                return {
                    'course': this.getCourseId(),
                    'field': 'com_end_date',
                    'direction': 'desc',
                    'printTotal': true,

                }
            },
            edition(){
                if(this.editions){
                    for(let i = 0; i < this.editions.length; i++){
                        if(this.editions[i].id == this.edition_id){
                            return this.editions[i];
                        }
                    }
                }

                return null;
            },
           constants(){
             return  Constants
           }
        },
        data() {
            return {
                selected: null,
                course: null,
                editions: null,
                edition_id: this.$route.params.editionId,
                pieKey: 'pie'+0,
                carouselKey: 0,
            }
        },
        methods: {
            forceRerenderGauge() {
                this.pieKey += 'pie'+1;
                this.carouselKey += 1;
            },
            backToSelection() {
                this.$router.push('/courses/' + this.course.area_id);
            },
            setSelected(selected) {
                if(this.selected == selected){
                    return;
                }
                this.selected = selected;
            },
            getCourseId() {
                return this.$route.params.courseId;
            },
            getEditionId() {
                if (this.editions[0].default_total){
                  return this.editions[0].id;
                }
                return this.$route.params.editionId;
            },
            setCourse(course) {
                this.course = course;
            },
            setEditions(editions){
                this.editions = editions.data;
            }
        },
        watch: {
            selected(n) {
                this.edition_id = this.editions[n].id;
            },
            editions(n){
                for(let i= 0; i < n.length; i++){
                    if(n[i].id == this.getEditionId()){
                        this.selected = i;
                    }
                }
            },
            edition() {
                this.pieKey += 1;
                this.carouselKey += 1;
            }
        }
    }
</script>

<style>
    .editions-chips:last-child div {
        margin-right: 1rem;;
    }
</style>