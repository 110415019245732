<template>
    <div class="flex flex-col flex-grow bg-module rounded-lg px-2 pt-2 pb-6 h-full" style="min-height: 430px">
        <div class="flex justify-between items-center">
            <span class="text-xl text-white self-start font-semibold pl-2">Funnel</span>
            <div class="flex items-start px-2 mt-1">
                <i class="mdi mdi-laptop text-visits mr-3 text-2xl leading-none"></i>
                <div class="flex flex-col items-end text-right leading-none">
                    <span class="text-visits block m-auto">{{ edition.visits | numberFormat }}</span>
                    <span class="text-visits text-xs">Visitas</span>
                </div>
            </div>
        </div>

        <div class="flex flex-col justify-enter mx-auto">
            <span class="text-secondary">Total</span>
            <!-- <span style="font-size: 9px" class="text-secondary -mt-1">(excepto visitas)</span> -->
            <span class="text-white text-2xl font-semibold">{{ total | numberFormat }}</span>
        </div>

        <div style="height: 2px" class="w-full mx-auto bg-separator2 mt-4"></div>
        <div style="height: 2px" class="w-full mx-auto bg-separator"></div>
        <div class="flex flex-col">
            
            <div v-for="(item,index) in funnel" :key="item.id" class="flex items-center justify-between">
                <template v-if="funnel.length == 5">
                    <div class="flex flex-col flex-1 min-w-auto">
                        <div class="flex items-center">
                            <span class="text-xs capitalize" :style="{color: colorsPlus[index]}">{{item.name}}</span>
                        </div>
                        <div :style="{width: item.percent + '%', backgroundColor: colorsPlus[index]}" class="h-4 rounded-r-md -mx-2"></div>
                    </div> 
                    <div class="flex flex-col bg-module shadowData w-12 mr-2 mt-2 h-12 rounded-2xl">
                        <span class="text-lg block m-auto" :style="{color: colorsPlus[index]}">{{ item.size | numberFormat }}</span>
                    </div>  
                </template>

                <template v-else>
                    <div class="flex flex-col flex-1 min-w-auto">
                        <div class="flex items-center">
                            <span class="text-xs capitalize" :style="{color: colors[index]}">
                                <span :class="{'uppercase' : item.id === 1}">{{item.name}}</span>
                                <span v-if="item.id === 1" class="text-blueSql font-medium"> / SQL en proceso</span>
                            </span>
                        </div>

                        <div :style="{width: item.percent + '%', backgroundColor: colors[index]}" class="h-5 rounded-r-md -mx-2 flex items-center">
                            <div 
                                v-if="item.id === 1" 
                                :title="'SQL en proceso: ' + item.sql_in_process_size"
                                v-tippy="{theme: 'translucent'}"
                                :style="{width: item.sql_in_process_percent + '%'}" 
                                class="bg-blueSql shadow-md h-3 rounded-r-md -mx-2"></div>
                        </div>
                    </div> 
                    <div class="flex flex-col bg-module shadowData w-12 mr-2 mt-2 h-12 rounded-2xl">
                        <span class="text-lg block m-auto" :style="{color: colors[index]}">{{ item.size | numberFormat }}</span>
                    </div>  
                </template>

            </div>
        </div>
        <Request model="Edition" :immediate="true" action="funnel" :form="form" @success="onSuccess" @error="onError"/>
    </div>
</template>

<script>
import {Request} from "@/api/components";
    export default {
        props: ['edition'],
        name: 'CoursesFunnel',
        components: { Request },
        data() {
            return {
                form: {
                    id: this.edition.id
                },
                funnel: null,
                colorsPlus: ['#76A9D5','#5A9DEC','#a576ef','#8958ff','#0089AA'],
                colors: ['#76A9D5','#24997e','#a576ef','#0089AA'],
            }
        },
        computed:{
            total(){
                return this.edition.leads_size +
                        this.edition.sql_size +
                        this.edition.opportunities_size +
                        this.edition.opportunities_plus_size +
                        this.edition.customers_size;

            }
        },
        methods: {
            onSuccess(funnel) {
                this.funnel = funnel.data;
            },
            onError(errors) {
                console.log(errors)
            }
        }
    }
</script>

<style>
.tippy-box {
    position: relative;
    background-color: #121822 !important;
}
.tippy-arrow {
    width: 16px;
    height: 16px;
    color: #121822 !important;
}
</style>